import React from 'react'
import { TriangleDiv } from "../components";
import Layout from '../components/layouts'
import * as ds from '../uiLibrary'
import { HeroBackground } from '../components/heroes'
import { Headline } from "../uiLibrary/Typographyv2";

const OptInPage = () => {
  return (
    <Layout>
      <HeroBackground>
        <ds.Background bg={ds.colors.midnight}>
          <ds.FixedWidthRow>
            <ds.Column span={10}>
              <Headline pt={ds.spacing.s5} mb={0} fontSize={[ds.typeScale.t6, ds.typeScale.t7, ds.typeScale.t8]} color={ds.brandColors.lightBlue}>STAY CONNECTED</Headline>
            </ds.Column>
          </ds.FixedWidthRow>
        </ds.Background>
      </HeroBackground>
      <ds.Background bg={"#202020"} pt={ds.spacing.s4} mt={0}>
        <TriangleDiv
          display={["none", "none", "block"]}
          bg={ds.colors.midnight}
          mt={0}
        />
      </ds.Background>
    </Layout>
  )
}

export default OptInPage
